import React, { useEffect, useState } from 'react';
import { useNavigate } from "react-router";
import { useAdminSpaceContext } from "../../hooks/context";
import {getSubscriptionCancellations,getShopSubscriptions,getUsers,getProducts,
    getCars,getOffers,getServices} from "../../../../services/AdminSapce/API";
import {useTranslation} from "react-i18next";

const AdminNavMenu = ({ roleId }) => {
    const { updateSelectedTab, selectedTab } = useAdminSpaceContext();
    const navigate = useNavigate();
    const [hasPendingItems, setHasPendingItems] = useState(false);
    const [hasSubscriptionAlerts, setHasSubscriptionAlerts] = useState(false);
    const [hasPendingVehicles, setHasPendingVehicles] = useState(false);
    const [isConfigOpen, setIsConfigOpen] = useState(false);
    const { t } = useTranslation();
    useEffect(() => {
        const savedTab = localStorage.getItem("selectedAdminTab");
        if (savedTab !== null) {
            updateSelectedTab(Number(savedTab));
        }
    }, [updateSelectedTab]);

    const checkSubscriptions = async () => {
        try {
            // Fetch subscription data
            const [subscriptionsResponse, cancellationsResponse] = await Promise.all([
                getShopSubscriptions(),
                getSubscriptionCancellations()
            ]);

            // Check for new subscriptions
            const hasNewSubscriptions = Array.isArray(subscriptionsResponse) &&
                subscriptionsResponse.some(sub => sub.status === "pending" || sub.status === "new");

            // Check for cancellation requests
            const hasCancellations = Array.isArray(cancellationsResponse) &&
                cancellationsResponse.some(cancel => cancel.status === "pending");

            return hasNewSubscriptions || hasCancellations;
        } catch (error) {
            return false;
        }
    };

    const checkPendingItems = async () => {
        try {
            // Fetch all validation data
            const [productResponse, serviceResponse, carsResponse, usersResponse, offersResponse] = await Promise.all([
                getProducts(),
                getServices(),
                getCars(),
                getUsers(),
                getOffers()
            ]);

            // Check for pending items in products
            const hasPendingProducts = Array.isArray(productResponse) &&
                productResponse.some(product => product.status === "pending");

            // Check for pending items in services
            const hasPendingServices = serviceResponse?.services &&
                serviceResponse.services.some(service => service.status === "pending");

            // Check for pending items in cars
            const hasPendingCars = Array.isArray(carsResponse) &&
                carsResponse.some(car => car.status === "pending");
            setHasPendingVehicles(hasPendingCars);

            // Check for pending sellers
            const hasPendingSellers = Array.isArray(usersResponse) &&
                usersResponse.some(user => user.role_id === 3 && user.status === "pending");

            // Check for pending offers
            const hasPendingOffers = Array.isArray(offersResponse) &&
                offersResponse.some(offer => offer.status === "pending");

            return hasPendingProducts || hasPendingServices || hasPendingCars || hasPendingSellers || hasPendingOffers;
        } catch (error) {
            return false;
        }
    };

    const checkAllNotifications = async () => {
        const [pendingStatus, subscriptionStatus] = await Promise.all([
            checkPendingItems(),
            checkSubscriptions()
        ]);

        setHasPendingItems(pendingStatus);
        setHasSubscriptionAlerts(subscriptionStatus);
    };

    // Effect for initial load and polling
    useEffect(() => {
        checkAllNotifications();

        const interval = setInterval(checkAllNotifications, 300000);

        return () => clearInterval(interval);
    }, []); // Initial setup

    // Effect to recheck notifications on tab change
    useEffect(() => {
        // Check if we're switching to or from relevant tabs
        if ([2, 12].includes(selectedTab)) {
            checkAllNotifications();
        }
    }, [selectedTab]); // Dependency on selectedTab

    const handleMenuClick = (index) => {
        updateSelectedTab(index);
        localStorage.setItem("selectedAdminTab", index);
        navigate(`/admin`);
    };

    const mainMenuItems = [
        { label: t("menu.dashboard"), icon: "dashboard" },
        { label: t("menu.users"), icon: "user" },
        { label: t("menu.promoCode"), icon: "offer" },
        { label: t("menu.validation"), icon: "confirm-check", showNotification: hasPendingItems },
        { label: t("menu.validationVehicle"), icon: "validationVehicules", showNotification: hasPendingVehicles },
        { label: t("menu.subscription"), icon: "soumission", showNotification: hasSubscriptionAlerts },
    ];

    const configMenuItems = [
        { label: t("menu.productConfig"), icon: "product" },
        { label: t("menu.serviceConfig"), icon: "service" },
        { label: t("menu.vehicleConfig"), icon: "car" },
        { label: t("menu.motoConfig"), icon: "motorcycle" },
        { label: t("menu.CamionConfig"), icon: "truck" },
        { label: t("menu.engineConfig"), icon: "engine" },
    ];

    return (
        <div
            className="min-w-[80px] max-w-[80px] lg:min-w-[250px] lg:max-w-[250px] min-h-[calc(100vh-88px)] max-h-[calc(100vh-88px)] hidden sm:flex flex-col justify-between border-r border-r-borderLight bg-white " >

            <div className="flex flex-col w-full h-fit">
                <AdminMenuWrapper>
                    {mainMenuItems.map((item, index) => {
                        if (roleId === 1 || (roleId === 2 && index !== 0 && index!== 5)) {
                            return (
                                <AdminMenuItem
                                    key={index}
                                    index={index}
                                    isActive={selectedTab === index}
                                    handleMenuClick={() => handleMenuClick(index)}
                                    icon={item.icon}
                                    label={item.label}
                                    showNotification={item.showNotification}
                                />
                            );
                        }
                        return null;
                    })}

                    {/* Config Dropdown */}
                    <div className="w-full ">
                        <div
                            onClick={() => setIsConfigOpen(!isConfigOpen)}
                            className={`w-full h-16 flex flex-row items-center gap-3 px-8 py-4 cursor-pointer relative`}
                        >
                            <div className="relative">
                                <img
                                    src="/resources/setting.svg"
                                    alt="Config"
                                    className={`w-5`}
                                />
                            </div>
                            <p className={`hidden lg:block leading-none font-medium text-sm `}>
                                {t("menu.configurations")}
                            </p>
                            <img
                                src="/resources/chevron-down.svg"
                                alt="expand"
                                className={`hidden lg:block ml-auto w-2 transition-transform ${isConfigOpen ? "rotate-180" : ""}`}
                            />
                        </div>

                        {isConfigOpen && (
                            <div className="bg-gray-50 overflow-y-auto max-h-56 overflow-x-hidden">
                                {configMenuItems.map((item, index) => {
                                    const actualIndex = mainMenuItems.length + index;
                                    if (roleId === 1 || (roleId === 2 && actualIndex !== 0 && actualIndex !== 5)) {
                                        return (
                                            <AdminMenuItem
                                                key={actualIndex}
                                                index={actualIndex}
                                                isActive={selectedTab === actualIndex}
                                                handleMenuClick={() => handleMenuClick(actualIndex)}
                                                icon={item.icon}
                                                label={item.label}
                                                showNotification={item.showNotification}
                                                isSubItem
                                            />
                                        );
                                    }
                                    return null;
                                })}
                            </div>
                        )}
                    </div>
                </AdminMenuWrapper>
            </div>
        </div>
    );
};

const AdminMenuWrapper = ({ children }) => {
    return (
        <div className="w-full h-full flex flex-col">
            {children}
        </div>
    );
};

const AdminMenuItem = ({ index, isActive, handleMenuClick, icon, label, showNotification, isSubItem = false }) => {
    return (
        <div
            onClick={handleMenuClick}
            className={`w-full h-16 flex flex-row items-center gap-3 ${isSubItem ? 'ml-1 px-7 ' : 'px-8 '} py-4  cursor-pointer relative ${isActive ? "bg-primaryExtraLight" : ""}`}>
            <div className="relative">
                <img
                    src={`/resources/${icon}.svg`}
                    alt={`Menu Item ${index}`}
                    className={`${isActive && "primary_filter"} w-5`}
                />
                {showNotification && (
                    <div className="absolute -top-1 -right-1 w-2 h-2 bg-red-500 rounded-full" />
                )}
            </div>
            <p className={`hidden lg:block leading-none font-medium text-sm ${isActive ? "text-primaryLight" : ""}`}>
                {label}
            </p>
        </div>
    );
};

export default AdminNavMenu;