import React, { useEffect, useState } from 'react';
import {
    Box,
    Typography,
    Avatar,
    Button,
    CircularProgress,
} from '@mui/material';
import CustomTabs from '../Tabs/Tabs';
import DataTable from '../dataTable/DataTable';
import {
    getBrands,
    getVehicles,
    getModels,
    getMotorisations,
    createVehiculeBrand,
    createModel,
    createMotorisation,
    createVehicle,
    updateMotorisation,
    updateModel,
    updateBrand,
    updateVehicle,
    ImportVehicle, deleteBrand, deleteModel, deleteMotorisation, deleteVehicle
} from '../../../../services/AdminSapce/API';
import ToastError, { toastStyle } from "../../../../components/Toasts/Error/ToastError";
import ToastSuccess from "../../../../components/Toasts/Success/ToastSuccess";
import {toast} from "sonner";
import GenericSelect from "../../../../components/GenericSelect/GenericSelect";
import GenericDropDown from "../../../../components/GenericDropDown/GenericDropDown";
import GenericDropDownItem from "../../../../components/GenericDropDown/GenericDropDownItem";
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import { useDropzone } from 'react-dropzone';
import { useTranslation } from 'react-i18next';

export default function VehicleConfig() {
    const { t } = useTranslation();

    const menuItems = [
        { id: 'brand', label: t('adminPages.vehicleConfig.brands'), icon: '/resources/product.svg' },
        { id: 'model', label: t('adminPages.vehicleConfig.models'), icon: '/resources/model.svg' },
        { id: 'motorisation', label: t('adminPages.vehicleConfig.motorizations'), icon: '/resources/motor.svg' },
        { id: 'vehicle', label: t('adminPages.vehicleConfig.vehicles'), icon: '/resources/car.svg' },
    ];

    const [currentTab, setCurrentTab] = useState('brand');
    const [rows, setRows] = useState([]);
    const [columns, setColumns] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
    const [formData, setFormData] = useState({});
    const [brands, setBrands] = useState([]);
    const [models, setModels] = useState([]);
    const [motorisations, setMotorisations] = useState([]);
    const [importLoading, setImportLoading] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const getDefaultColumns = (tab) => {
        switch(tab) {
            case 'brand':
                return [
                    {
                        field: 'brand',
                        headerName: t('adminPages.vehicleConfig.brand.title'),
                        width: 300,
                        renderCell: (params) => (
                            <Box display="flex" alignItems="center">
                                <Avatar alt={params.row.name} src={params.row.logo} style={{ marginRight: 10 }} />
                                {params.row.name}
                            </Box>
                        )
                    },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 200 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 150,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={1}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            case 'model':
                return [
                    { field: 'name', headerName: t('adminPages.vehicleConfig.model.name'), width: 200 },
                    { field: 'serie', headerName: t('adminPages.vehicleConfig.model.series'), width: 150 },
                    { field: 'brand', headerName: t('adminPages.vehicleConfig.model.brand'), width: 200 },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 200 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 150,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={1}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            case 'motorisation':
                return [
                    { field: 'name', headerName: t('adminPages.vehicleConfig.motorization.name'), width: 150 },
                    { field: 'fuel', headerName: t('adminPages.vehicleConfig.motorization.fuel'), width: 150 },
                    { field: 'model', headerName: t('adminPages.vehicleConfig.motorization.model'), width: 150 },
                    { field: 'brand', headerName: t('adminPages.vehicleConfig.brand.title'), width: 150 },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 150 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 150,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={1}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            case 'vehicle':
                return [
                    { field: 'motorisation', headerName: t('adminPages.vehicleConfig.vehicle.motorization'), width: 150 },
                    { field: 'fuel', headerName: t('adminPages.vehicleConfig.motorization.fuel'), width: 150 },
                    { field: 'model', headerName: t('adminPages.vehicleConfig.model.title'), width: 150 },
                    { field: 'brand', headerName: t('adminPages.vehicleConfig.brand.title'), width: 150 },
                    { field: 'created_at', headerName: t('adminPages.vehicleConfig.common.createdAt'), width: 150 },
                    {
                        field: 'actions',
                        headerName: '',
                        width: 100,
                        sortable: true,
                        filterable: true,
                        renderCell: (params) => (
                            <Box display="flex" >
                                <GenericDropDown>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.modify')} index={1}
                                                         handleClick={() => handleEdit(params.row)}/>
                                    <GenericDropDownItem value={t('adminPages.productConfig.buttons.delete')} index={1}
                                                         handleClick={() => handleDelete(params.row)}/>
                                </GenericDropDown>
                            </Box>
                        ),
                    }
                ];
            default:
                return [];
        }
    };

    const onDrop = async (acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file && file.name.endsWith('.csv')) {
            handleImportCSV(file);
        } else {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.invalidFile')}/>, toastStyle);
        }
    };

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        accept: {
            'text/csv': ['.csv']
        },
        multiple: false
    });

    const handleImportCSV = async (file) => {
        try {
            setImportLoading(true);
            const formData = new FormData();
            formData.append('file', file);
            formData.append('type', 'car');
            await ImportVehicle(formData);
            toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.vehicle.success.import')}/>, toastStyle);
            fetchData(currentTab);
            setIsUploadModalOpen(false);
        } catch (error) {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.import')}/>, toastStyle);
        } finally {
            setImportLoading(false);
        }
    };

    const fetchData = async (tab) => {
        setLoading(true);
        setError(null);
        // Set default columns immediately when tab changes
        setColumns(getDefaultColumns(tab));
        try {
            let data;
            switch (tab) {
                case 'brand':
                    data = await getBrands();
                    // Filter to only show car brands
                    data = data.filter(brand => brand.type === 'car');
                    setRows(data.map(brand => ({
                        id: brand.id,
                        name: brand.brand_name,
                        logo: brand.logo,
                        created_at:new Date(brand.created_at).toLocaleDateString("fr-FR")
                    })));
                    setBrands(data);
                    break;
                case 'model':
                    data = await getModels();
                    // Filter to only show car models
                    data = data.filter(model => model.vehicle_brand?.type === 'car');
                    setRows(data.map(model => ({
                        id: model.id,
                        name: model.name,
                        serie: model.serie,
                        brand: model.vehicle_brand?.brand_name,
                        vehicle_brand_id: model.vehicle_brand?.id,
                        created_at: new Date(model.created_at).toLocaleDateString("fr-FR")
                    })));
                    break;
                case 'motorisation':
                    data = await getMotorisations();
                    // Filter to only show car motorisations
                    data = data.filter(motor => motor.vehicle_model?.vehicle_brand?.type === 'car');
                    setRows(data.map(motor => ({
                        id: motor.id,
                        name: motor.name,
                        fuel: motor.fuel,
                        model: motor.vehicle_model?.name,
                        brand: motor.vehicle_model?.vehicle_brand?.brand_name,
                        created_at: new Date(motor.created_at).toLocaleDateString("fr-FR")
                    })));
                    setMotorisations(data);
                    break;
                case 'vehicle':
                    data = await getVehicles();
                    // Only map car data
                    setRows(data.cars.map(vehicle => ({
                        id: vehicle.id,
                        motorisation: vehicle.motorisation?.name,
                        fuel: vehicle.motorisation?.fuel,
                        model: vehicle.motorisation?.vehicle_model?.name,
                        brand: vehicle.motorisation?.vehicle_model?.vehicle_brand?.brand_name,
                        created_at: new Date(vehicle.created_at).toLocaleDateString("fr-FR")
                    })));
                    // Filter motorisations to only show car motorisations
                    const motorisationData = await getMotorisations();
                    setMotorisations(motorisationData.filter(motor => motor.vehicle_model?.vehicle_brand?.type === 'car'));
                    break;
                default:
                    break;
            }
        } catch (err) {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.fetch')}/>, toastStyle);
            setError('Failed to fetch data');
            setRows([]);
        } finally {
            setLoading(false);
        }
    };

    const fetchModels = async () => {
        const data = await getModels();
        // Filter to only show car models
        setModels(data.filter(model => model.vehicle_brand?.type === 'car'));
    };

    useEffect(() => {
        fetchData(currentTab);
        if (currentTab === 'model' || currentTab === 'motorisation') {
            fetchModels();
        }
    }, [currentTab]);

    const handleTabChange = (tabId) => {
        setCurrentTab(tabId);
        localStorage.setItem('currentTab', tabId);
        fetchData(tabId);
    };

    const handleEdit = (row) => {
        setFormData({
            ...row,
            brand_name: row.name,
            vehicle_brand_id: row.vehicle_brand_id
        });
        setIsEditing(true);
        setIsModalOpen(true);
    };
    const handleDelete = async (row) => {
        try {
            switch (currentTab) {
                case 'brand':
                    await deleteBrand(row.id);
                    toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.brand.success.delete')}/>, toastStyle);
                    break;
                case 'model':
                    await deleteModel(row.id);
                    toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.model.success.delete')}/>, toastStyle);
                    break;
                case 'motorisation':
                    await deleteMotorisation(row.id);
                    toast.success(<ToastSuccess
                        message={t('adminPages.vehicleConfig.motorization.success.delete')}/>, toastStyle);
                    break;
                case 'vehicle':
                    await deleteVehicle(row.id);
                    toast.success(<ToastSuccess
                        message={t('adminPages.vehicleConfig.vehicle.success.delete')}/>, toastStyle);
                    break;
            }
            fetchData(currentTab);
        }catch (e) {
            toast.error(<ToastError message={t('adminPages.vehicleConfig.errors.delete')}/>, toastStyle);
        }
    }
    const handleAddClick = () => {
        setFormData({});
        setIsEditing(false);
        setIsModalOpen(true);
    };

    const handleFormSubmit = async (event) => {
        event.preventDefault();
        try {
            if (isEditing) {
                switch (currentTab) {
                    case 'brand':
                        await updateBrand(formData.id, {...formData, type: "car"});
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.brand.success.update')}/>, toastStyle);
                        break;
                    case 'model':
                        await updateModel(formData.id, formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.model.success.update')}/>, toastStyle);
                        break;
                    case 'motorisation':
                        await updateMotorisation(formData.id, formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.motorization.success.update')}/>, toastStyle);
                        break;
                    case 'vehicle':
                        await updateVehicle(formData.id, formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.vehicle.success.update')}/>, toastStyle);
                        break;
                }
            } else {
                switch (currentTab) {
                    case 'brand':
                        await createVehiculeBrand({...formData, type: "car"});
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.brand.success.add')}/>, toastStyle);
                        break;
                    case 'model':
                        await createModel(formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.model.success.add')}/>, toastStyle);
                        break;
                    case 'motorisation':
                        await createMotorisation(formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.motorization.success.add')}/>, toastStyle);
                        break;
                    case 'vehicle':
                        await createVehicle(formData);
                        toast.success(<ToastSuccess message={t('adminPages.vehicleConfig.vehicle.success.add')}/>, toastStyle);
                        break;
                }
            }
            fetchData(currentTab);
            setIsModalOpen(false);
        } catch (error) {
            toast.error(<ToastError message={isEditing ? t('adminPages.vehicleConfig.errors.update') : t('adminPages.vehicleConfig.errors.add')}/>, toastStyle);
        }
    };

    function handleCancel() {
        setIsModalOpen(false);
    }

    return (
        <Box className="w-full h-full flex flex-col">
            <Box p={2} className="rounded-md">
                <CustomTabs menuItems={menuItems} onTabChange={handleTabChange} />
            </Box>
            <Box p={3} flex="1" display="flex" flexDirection="column">
                {loading ? (
                    <div className="flex items-center justify-center w-full h-full">
                        <div className="loader" />
                    </div>
                ) : error ? (
                    <Typography color="error">{error}</Typography>
                ) : (
                    <>
                        <DataTable
                            key={currentTab}
                            rows={rows}
                            columns={columns}
                            showExportImport={currentTab === 'vehicle'}
                            showActions={false}
                            showAddButton={true}
                            onAddRow={handleAddClick}
                            handleFileUpload={() => setIsUploadModalOpen(true)}
                            showSelectAll={false}
                            showApproveRejectButtons={false}
                            tabLabel={menuItems.find((item) => item.id === currentTab)?.label}
                        />
                    </>
                )}
            </Box>

            {/* Form Modal */}
            {isModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-4">
                            {isEditing ? t('adminPages.productConfig.buttons.modify') : t('adminPages.vehicleConfig.common.add')} {menuItems.find((item) => item.id === currentTab)?.label}
                        </h2>

                        <form onSubmit={handleFormSubmit}>
                            {/* Form for Brand */}
                            {currentTab === 'brand' && (
                                <>
                                    <label className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.vehicleConfig.brand.name')}</label>
                                    <input
                                        className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                        value={formData.brand_name || ''}
                                        placeholder={t('adminPages.vehicleConfig.brand.placeholder.name')}
                                        onChange={(e) => setFormData({...formData, brand_name: e.target.value})}
                                    />
                                    <label className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.brand.logo')}</label>
                                    <input
                                        className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                        placeholder={t('adminPages.vehicleConfig.brand.placeholder.logo')}
                                        value={formData.logo || ''}
                                        onChange={(e) => setFormData({...formData, logo: e.target.value})}
                                    />
                                </>
                            )}

                            {/* Form for Model */}
                            {currentTab === 'model' && (
                                <>
                                    <label
                                        className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.model.brand')}</label>
                                    <GenericSelect
                                        isMulti={false}
                                        inputs={brands.map(brand => ({value: brand.id, label: brand.brand_name}))}
                                        defaults={brands.filter(brand => formData.vehicle_brand_id === brand.id).map(brand => ({
                                            value: brand.id,
                                            label: brand.brand_name
                                        }))}
                                        handleChanges={(selectedOption) => setFormData({
                                            ...formData,
                                            vehicle_brand_id: selectedOption.value
                                        })}
                                        setWidth="100%"
                                    />
                                    <label
                                        className="block text-gray-600  mt-4 text-sm font-light">{t('adminPages.vehicleConfig.model.name')}</label>
                                    <input
                                        className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                        value={formData.name || ''}
                                        placeholder={t('adminPages.vehicleConfig.model.placeholder.name')}
                                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                                    />
                                    <label
                                        className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.model.series')}</label>
                                    <input
                                        className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                        value={formData.serie || ''}
                                        placeholder={t('adminPages.vehicleConfig.model.placeholder.series')}
                                        onChange={(e) => setFormData({...formData, serie: e.target.value})}
                                    />

                                </>
                            )}

                            {/* Form for Motorisation */}
                            {currentTab === 'motorisation' && (
                                <>
                                    <label
                                        className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.motorization.model')}</label>
                                    <GenericSelect
                                        isMulti={false}
                                        inputs={models.map(model => ({value: model.id, label: model.name}))}
                                        defaults={models.filter(model => formData.vehicle_model_id === model.id).map(model => ({
                                            value: model.id,
                                            label: model.name
                                        }))}
                                        handleChanges={(selectedOption) => setFormData({
                                            ...formData,
                                            vehicle_model_id: selectedOption.value
                                        })}
                                        setWidth="100%"
                                    />
                                    <label
                                        className="block text-gray-600 mb-1   mt-4  text-sm font-light">{t('adminPages.vehicleConfig.motorization.name')}</label>
                                    <input
                                        className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                        value={formData.name || ''}
                                        placeholder={t('adminPages.vehicleConfig.motorization.placeholder.name')}
                                        onChange={(e) => setFormData({...formData, name: e.target.value})}
                                    />
                                    <label
                                        className="block text-gray-600 mb-1 mt-4 text-sm font-light">{t('adminPages.vehicleConfig.motorization.fuel')}</label>
                                    <input
                                        className="w-full h-10 border border-gray-300 rounded px-3 focus:outline-none focus:border-orange-400"
                                        value={formData.fuel || ''}
                                        placeholder={t('adminPages.vehicleConfig.motorization.placeholder.fuel')}
                                        onChange={(e) => setFormData({...formData, fuel: e.target.value})}
                                    />

                                </>
                            )}

                            {/* Form for Vehicle */}
                            {currentTab === 'vehicle' && (
                                <>
                                    <label
                                        className="block text-gray-600 mb-1 text-sm font-light">{t('adminPages.vehicleConfig.vehicle.motorization')}</label>
                                    <GenericSelect
                                        isMulti={false}
                                        inputs={motorisations.map(motor => ({value: motor.id, label: motor.name}))}
                                        defaults={motorisations.filter(motor => formData.motorisation_id === motor.id).map(motor => ({
                                            value: motor.id,
                                            label: motor.name
                                        }))}
                                        handleChanges={(selectedOption) => setFormData({ ...formData, motorisation_id: selectedOption.value })}
                                        setWidth="100%"
                                    />
                                </>
                            )}
                        </form>

                        <div className="flex justify-end gap-2 mt-6">
                            <button
                                onClick={handleCancel}
                                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded"
                            >
                                {t('adminPages.vehicleConfig.common.cancel')}
                            </button>
                            <button
                                onClick={handleFormSubmit}
                                className="px-4 py-2 bg-orange-500 text-white rounded hover:bg-orange-600 disabled:opacity-50"
                                disabled={loading}
                            >
                                {loading ? t('adminPages.vehicleConfig.common.adding') :
                                    isEditing ? t('adminPages.productConfig.buttons.modify') : t('adminPages.productConfig.buttons.modify')}
                            </button>
                        </div>
                    </div>
                </div>
            )}

            {/* Upload Modal */}
            {isUploadModalOpen && (
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
                    <div className="bg-white rounded-lg p-6 w-full max-w-md">
                        <h2 className="text-xl font-semibold mb-4">
                            {t('adminPages.vehicleConfig.common.add')}
                        </h2>

                        <div
                            {...getRootProps()}
                            className={`w-full p-8 border-2 border-dashed rounded-lg text-center transition-all cursor-pointer
                                ${isDragActive ? 'border-orange-500 bg-orange-50' : 'border-gray-300 hover:border-orange-400 hover:bg-orange-50'}`}
                        >
                            <input {...getInputProps()} />
                            <div className="flex flex-col items-center justify-center space-y-4">
                                {importLoading ? (
                                    <>
                                        <CircularProgress style={{ color: '#f97316' }} />
                                        <Typography variant="h6" style={{ color: '#f97316' }}>
                                            {t('adminPages.vehicleConfig.common.importing')}
                                        </Typography>
                                    </>
                                ) : (
                                    <>
                                        <CloudUploadIcon style={{ fontSize: 48, color: '#f97316' }} />
                                        <Typography variant="h6" style={{ color: '#f97316' }}>
                                            {isDragActive
                                                ? t('adminPages.vehicleConfig.dropzone.active')
                                                : t('adminPages.vehicleConfig.dropzone.inactive')}
                                        </Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            {t('adminPages.vehicleConfig.dropzone.click')}
                                        </Typography>
                                        <Typography variant="caption" color="textSecondary">
                                            {t('adminPages.vehicleConfig.dropzone.format')}
                                        </Typography>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="flex justify-end mt-6">
                            <button
                                onClick={() => setIsUploadModalOpen(false)}
                                className="px-4 py-2 text-gray-600 hover:bg-gray-100 rounded text-[#f97316]"
                            >
                                {t('adminPages.vehicleConfig.common.close')}
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </Box>
    );
}